import qs from 'qs';
import fileDownload from 'js-file-download';
import { Message } from 'element-ui';
import deptGroupApi from 'request/api/deptGroupApi';

export default {
    //商品管理
    GoodsCategories(vm, success, failure) {
        if (vm.$store.state.metaCategories.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaCategories)));
            return;
        }
        this.QueryRemote(vm, '/goods/category/list', success, failure, 'saveMetaCategories');
    },
    GroupedGoodsCategories(vm, success, failure) {
        const grouped = function (cats) {
            const gp = [];
            cats.forEach((ele, idx) => {
                const _obj = {};
                Object.assign(_obj, ele);
                if (_obj.level == 1) {
                    gp.push(
                        (() => {
                            return _obj;
                        })()
                    );
                } else {
                    gp[gp.length - 1].sub = gp[gp.length - 1]['sub'] || [];
                    gp[gp.length - 1].sub.push(
                        (() => {
                            return _obj;
                        })()
                    );
                }
            });
            return gp;
        };

        return this.GoodsCategories(
            vm,
            (data) => {
                success(grouped(data));
            },
            failure
        );
    },
    GoodsBrands(vm, success, failure) {
        if (vm.$store.state.metaBrands.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaBrands)));
            return;
        }
        this.QueryRemote(vm, '/goods/brand/list', success, failure, 'saveMetaBrands');
    },
    GoodsSupplier(vm, success, failure) {
        if (vm.$store.state.metaSupplier.length) {
            success(vm.$store.state.metaSupplier);
            success(JSON.parse(JSON.stringify(vm.$store.state.metaSupplier)));
            return;
        }
        this.QueryRemote(vm, '/goods/supplier/list', success, failure, 'saveMetaSupplier');
    },
    GoodsCardType(vm, success, failure) {
        if (vm.$store.state.metaCardType.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaCardType)));
            return;
        }
        this.QueryRemote(vm, '/member/cardType/list', success, failure, 'saveMetaCardType');
    },

    //广告管理
    Customer(vm, success, failure) {
        if (vm.$store.state.metaCustomer.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaCustomer)));
            return;
        }
        this.QueryRemote(vm, '/advert/customer/list', success, failure, 'saveMetaCustomer');
    },

    //系统管理
    AllMenus(vm, success, failure) {
        if (vm.$store.state.metaAllMenus.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaAllMenus)));
            return;
        }
        this.QueryRemote(vm, '/system/menu/list', success, failure, 'saveMetaAllMenus');
    },
    AllMenusPrivs(vm, success, failure) {
        if (vm.$store.state.metaAllMenusPrivs.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaAllMenusPrivs)));
            return;
        }
        this.QueryRemote(vm, '/system/role/menuPrivs', success, failure, 'saveMetaAllMenusPrivs');
    },
    OpenMenusPrivs(vm, success, failure) {
        if (vm.$store.state.metaAllMenusPrivs.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaAllMenusPrivs)));
            return;
        }
        this.QueryRemote(vm, '/system/deptGroupRole/open/menu', success, failure, 'saveMetaAllMenusPrivs');
    },
    AllRoles(vm, success, failure) {
        if (vm.$store.state.metaAllRoles.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaAllRoles)));
            return;
        }
        this.QueryRemote(vm, '/system/role/companyAllRoles', success, failure, 'saveMetaAllRoles');
    },
    /**
     * //按编码查询dept,服务端组织分组,当前登录用户机构类型限定,不受当前用户管理归属限定
     * @param vm
     * @param success
     * @param failure
     * @constructor
     */
    DeptTrees(vm, success, failure) {
        if (vm.$store.state.metaDeptTrees.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaDeptTrees)));
            return;
        }
        this.QueryRemote(vm, '/system/dept/tree', success, failure, 'saveDeptTrees');
    },

    /**
     * 查询当前员工管理或所属的机构组列表
     * @param vm
     * @param success
     * @constructor
     */
    DeptGroup(vm, success) {
        if (vm.$store.state.metaDeptGroup.length) {
            success(JSON.parse(JSON.stringify(vm.$store.state.metaDeptGroup)));
            return;
        }
        deptGroupApi.managedAllDeptGroups().then((rst) => {
            success(rst);
        });
    },

    HasBigUnitPrivilege(vm, deptCode, success) {
        const _url = '/system/deptPrivilege/hasDeptPrivilege?flag=dept.biz.goods.allow.bigUnit&deptCodes=';
        this.QueryRemote(vm, _url + deptCode, success);
    },

    //支付模板
    PayTemplate(vm, success, failure) {
        this.QueryRemote(vm, '/finance/pay/template/list', success, failure);
    },

    /******
     * 通用查询模板*/
    QueryRemote(vm, url, success, failure, commit) {
        failure = failure || function () {};
        vm.$http
            .get(url)
            .then((rst) => {
                if (rst.data.status === 200) {
                    /**因为业务中没有清除缓存等逻辑，所以暂时注释掉store中commit逻辑*/
                    /*if (commit) {
                    vm.$store.commit(commit, (rst.data['data'] || null));
                }*/
                    if (typeof rst.data['data'] != 'undefined') {
                        success(JSON.parse(JSON.stringify(rst.data['data'] || rst.data['data'])));
                    } else {
                        success();
                    }
                } else {
                    failure(rst.data.message);
                }
            })
            .catch((rst) => {
                this._processResponse(rst.response, failure);
            });
    },
    /******
     * post 请求的查询商品信息*/
    QueryGoodsInfoByCodes(vm, url, params, success, failure) {
        vm.$http({
            url: url,
            method: 'post',
            data: params,
            transformRequest: [
                function (data, headers) {
                    let ret = '';
                    for (const it in data) {
                        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
                    }
                    return ret;
                },
            ],
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        })
            .then((rst) => {
                if (rst.data.status === 200) {
                    /**因为业务中没有清除缓存等逻辑，所以暂时注释掉store中commit逻辑*/
                    /*if (commit) {
                    vm.$store.commit(commit, (rst.data['data'] || null));
                }*/
                    if (typeof rst.data['data'] != 'undefined') {
                        success(JSON.parse(JSON.stringify(rst.data['data'] || rst.data['data'])));
                    } else {
                        success();
                    }
                } else {
                    failure(rst.data.message);
                }
            })
            .catch((rst) => {
                this._processResponse(rst.response, failure);
            })
            .finally(() => {
                if (vm.tableLoading) {
                    vm.tableLoading = false;
                }
            });
    },
    PatchRemote(vm, url, params, config, success, failure) {
        config = config || {};
        const headers = config['header'] || {};
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        config.headers = headers;
        Object.assign(config, {
            transformRequest: [
                function (data) {
                    return qs.stringify(data, { indices: false });
                },
            ],
        });
        const _patch = () => {
            return vm.$http.patch(url, params, config);
        };
        const _message = params.message || '是否确认变更该信息?';
        const _cancelMethod = params.cancelMethod || function () {};
        delete params['message'];
        this._commonDml(vm, {
            success:
                success ||
                function () {
                    Message.success({ message: '保存成功' });
                    vm.goBack();
                },
            failure: failure,
            message: _message,
            cancelMethod: _cancelMethod,
            methods: _patch,
        });
    },
    PostRemote(vm, url, params, config, success, failure) {
        config = config || {};
        const headers = config['header'] || {};
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        config.headers = headers;
        Object.assign(config, {
            transformRequest: [
                function (data) {
                    return qs.stringify(data, { indices: false });
                },
            ],
        });
        const _post = () => {
            return vm.$http.post(url, params, config);
        };
        const _message = params.message || '是否确认保存该信息?';
        delete params['message'];
        this._commonDml(vm, {
            failure: failure,
            message: _message,
            methods: _post,
            success:
                success ||
                function () {
                    Message.success({ message: '保存成功' });
                    vm.goBackAndReload();
                },
        });
    },
    PostPriceBatchRemote(vm, url, params, config, success, failure) {
        config = config || {};
        const headers = config['header'] || {};
        headers['Content-Type'] = 'application/json; charset=UTF-8';
        config.headers = headers;
        Object.assign(config, {
            transformRequest: [
                function (data) {
                    return JSON.stringify(data);
                },
            ],
        });
        const _post = () => {
            return vm.$http.post(url, params, config);
        };
        const _message = params.message || '是否确认保存该信息?';
        delete params['message'];
        this._commonDml(vm, {
            failure: failure,
            message: _message,
            methods: _post,
            success:
                success ||
                function () {
                    Message.success({ message: '保存成功' });
                    vm.goBackAndReload();
                },
        });
    },
    PostGoodsplan(vm, url, params, config, success, failure) {
        config = config || {};
        const headers = config['header'] || {};
        headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
        config.headers = headers;
        Object.assign(config, {
            transformRequest: [
                function (data) {
                    return qs.stringify(data, { indices: false });
                },
            ],
        });
        const _post = () => {
            return vm.$http.post(url, params, config);
        };
        const _message = params.message || '是否确认保存该信息?';
        delete params['message'];
        this._commonDml(vm, {
            failure: failure,
            message: _message,
            methods: _post,
            success: success,
        });
    },
    DeleteRemote(vm, url, code, message, success, failure) {
        const _delete = () => {
            return vm.$http.delete(url + '?code' + '=' + code);
        };
        this._commonDml(vm, {
            success: success,
            failure: failure,
            message: message || '是否删除该信息,删除后不可恢复,是否确认删除?',
            methods: _delete,
        });
    },

    Export(vm, fileName, url, params, codes = []) {
        //如果codes不为空，则优先根据codes导出数据，否则根据params导出
        let queryParam;
        if (codes.length > 0) {
            queryParam = { codes: codes };
        } else {
            queryParam = JSON.parse(JSON.stringify(params));
            delete queryParam.limit;
            delete queryParam.page;
        }
        vm.loadingStart('下载中,如果页面数据较多,可能等待下载时间可能较长,请耐心等待.........');
        vm.$http
            .get(url + '?' + qs.stringify(queryParam), { responseType: 'arraybuffer', timeout: 300000 })
            .then((res) => {
                vm.loadingStop();
                try {
                    //判断返回的是不是文件
                    if (
                        res.headers['content-type'] &&
                        res.headers['content-type'].search('multipart/form-data') === -1
                    ) {
                        //不是文件就转换为异常消息
                        const responseObj = JSON.parse(Buffer.from(res.data).toString());
                        if (responseObj.message) {
                            //有消息 提示消息
                            vm.$message.error(responseObj.message);
                            return;
                        }
                    }
                    //是文件就处理文件
                    fileName = decodeURIComponent(escape(atob(res.headers['content-disposition'].split('=')[1])));
                } catch (e) {
                    fileName = fileName + '.xls';
                }
                fileDownload(res.data, fileName);
            })
            .catch(() => {
                vm.loadingStop();
                vm.$message.error('下载失败');
            });
    },

    ExportPost(vm, fileName, url, params, codes = []) {
        //如果codes不为空，则优先根据codes导出数据，否则根据params导出
        let queryParam;
        if (codes.length > 0) {
            queryParam = { codes: codes };
        } else {
            queryParam = JSON.parse(JSON.stringify(params));
            delete queryParam.limit;
            delete queryParam.page;
        }
        vm.loadingStart('下载中,如果页面数据较多,可能等待下载时间可能较长,请耐心等待.........');
        vm.$http
            .post(url, queryParam, {
                responseType: 'arraybuffer',
                timeout: 300000,
                'Content-Type': 'application/json; charset=UTF-8',
            })
            .then((res) => {
                vm.loadingStop();
                try {
                    //判断返回的是不是文件
                    if (
                        res.headers['content-type'] &&
                        res.headers['content-type'].search('multipart/form-data') === -1
                    ) {
                        //不是文件就转换为异常消息
                        const responseObj = JSON.parse(Buffer.from(res.data).toString());
                        if (responseObj.message) {
                            //有消息 提示消息
                            vm.$message.error(responseObj.message);
                            return;
                        }
                    }
                    //是文件就处理文件
                    fileName = decodeURIComponent(escape(atob(res.headers['content-disposition'].split('=')[1])));
                } catch (e) {
                    fileName = fileName + '.xls';
                }
                fileDownload(res.data, fileName);
            })
            .catch(() => {
                vm.loadingStop();
                vm.$message.error('下载失败');
            });
    },

    _commonDml(vm, config) {
        const _config = {
            methods: config.methods,
            message: config.message || '',
            failure: config.failure || function () {},
            cancelMethod: config.cancelMethod,
            success:
                config.success ||
                function (data) {
                    vm.$message.success('操作成功');
                    if (vm['handleQuery']) {
                        vm.handleQuery();
                    }
                },
        };
        vm.$confirm(_config.message, '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        })
            .then(() => {
                vm.loadingStart('请稍等......');
                return _config.methods();
            })
            .then((rst) => {
                if (rst.data.status === 200) {
                    vm.loadingStop();
                    _config.success(rst.data.data);
                }
            })
            .catch((rst) => {
                vm.loadingStop();
                if (rst == 'cancel') {
                    if (typeof _config.cancelMethod === 'function') {
                        _config.cancelMethod();
                    }
                } else {
                    if (typeof _config.failure === 'function') {
                        _config.failure(rst);
                    }
                }
            });
    },
    _processResponse(response, failure) {
        if (response['response']) {
            response = response.response;
        }
        if (response['data']) {
            failure(response.data.message);
        } else {
            console.error(response);
        }
    },
};
